var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Nouveau type du frais "),_c('v-spacer'),_c('v-btn',{staticClass:"v-btn--active",attrs:{"icon":"","color":"primary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-6"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.send.apply(null, arguments)}}},[_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.language_tab),callback:function ($$v) {_vm.language_tab=$$v},expression:"language_tab"}},[_c('v-tabs-slider',{attrs:{"color":"yellow"}}),_c('v-tab',[_vm._v(" Francais ")]),_c('v-tab',[_vm._v(" Arabe ")])],1),_c('v-tabs-items',{model:{value:(_vm.language_tab),callback:function ($$v) {_vm.language_tab=$$v},expression:"language_tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Désignation *","error-messages":_vm.formErrors && _vm.formErrors.name ? _vm.formErrors.name[0] : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.name
                      ? (_vm.formErrors.name = '')
                      : false}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)],1),_c('v-tab-item',{staticStyle:{"direction":"rtl"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"arabic"},[_c('v-text-field',{attrs:{"label":"Désignation en arabe *","error-messages":_vm.formErrors && _vm.formErrors.name_ar
  ? _vm.formErrors.name_ar[0]
  : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.name_ar
                      ? (_vm.formErrors.name_ar = '')
                      : false}},model:{value:(_vm.form.name_ar),callback:function ($$v) {_vm.$set(_vm.form, "name_ar", $$v)},expression:"form.name_ar"}})],1)],1)],1)],1),_c('v-btn',{staticClass:"rounded-lg text-none mt-3",attrs:{"color":"primary","loading":_vm.btnLoading,"large":"","depressed":""},on:{"click":_vm.send}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-send-outline")]),_vm._v(" Savegarder ")],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }