<template>
  <div>
    <v-dialog v-model="openDialog" max-width="600" persistent>
      <v-card>
        <v-card-title>
          Nouveau type du frais
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <form @submit.prevent="send">
            <v-tabs v-model="language_tab" align-with-title>
              <v-tabs-slider color="yellow"></v-tabs-slider>

              <v-tab> Francais </v-tab>
              <v-tab> Arabe </v-tab>
            </v-tabs>

            <v-tabs-items v-model="language_tab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-text-field label="Désignation *" @input="
                      formErrors && formErrors.name
                        ? (formErrors.name = '')
                        : false
                      " :error-messages="formErrors && formErrors.name ? formErrors.name[0] : ''
    " v-model="form.name" />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item style="direction: rtl">
                <v-card flat>
                  <v-card-text class="arabic">
                    <v-text-field label="Désignation en arabe *" @input="
                      formErrors && formErrors.name_ar
                        ? (formErrors.name_ar = '')
                        : false
                      " :error-messages="formErrors && formErrors.name_ar
    ? formErrors.name_ar[0]
    : ''
    " v-model="form.name_ar" />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>


            <v-btn color="primary" class="rounded-lg text-none mt-3" :loading="btnLoading" large depressed @click="send">
              <v-icon left>mdi-send-outline</v-icon>
              Savegarder
            </v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      btnLoading: false,

      formErrors: {},
      form: {
        name: "",
        name_ar: ""
      },
      openDialog: false,
      language_tab: null,
      service_type_id: null,

      item: null
    };
  },

  computed: {},
  methods: {
    open(item) {
      this.item = item;

      if (item) {
        this.form = {
          id: item.id,
          name: item.name,
          name_ar: item.name_ar
        };
      }

      this.openDialog = true;
    },
    close() {
      this.openDialog = false;
    },
    send() {
      this.btnLoading = true;
      var url = "v1/fees/types/store";
      if (this.item) {
        url = "v1/fees/types/update";

      }
      HTTP.post(url, this.form, { params: {} })
        .then(() => {
          this.$successMessage = "Enregistrment avec succès";
          this.btnLoading = false;
          this.close();
          this.form = {};

          this.openDialog = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          this.btnLoading = false;
          this.formErrors = err.response.data.errors;
        });
    },

    uploadFile(file) {
      this.form.icon = file;
      console.log("icon changed");
    },
  },
  created() { },
};
</script>
    
<style scoped>
.arabic .v-text-field__slot .v-label {
  text-align: right !important;
  direction: rtl;
  left: inherit;
  right: 0 !important;
}
</style>
    